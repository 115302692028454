@import 'app/variables.scss';
@import 'app/mixins.scss';

$pagesWidth: 150px;

.PdfViewer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .outer {
    position: relative;
    background: transparent !important;
    display: flex;
    height: 100%;
    width: 100%;
    flex: 1;

    .inner {
      flex: 1;
      width: auto;
      overflow-x: hidden;

      .document {
        width: 100%;
        overflow: hidden;

        pre {
          border: unset;
          box-shadow: unset;
        }
      }

      .page {
        margin: 0;
        padding: 0;
      }

      .react-pdf__Page__canvas,
      .react-pdf__Page__textContent {
        /* center each page */
        margin: auto;
        padding-bottom: $aleph-grid-size;
      }

      .react-pdf__Page__canvas {
        padding: 0 !important;
      }

      .react-pdf__message--error {
        text-align: center;
        padding: 100px 20px;
        font-size: 1.2em;
        width: 100%;
      }
    }
  }
}
